import React from "react";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const ImageLoader = ({ path, findImg, index, className, notFoundImg, styleObject }: any) => {
  const [src, setSrc] = useState(path);
  const [isLoading, setIsLoading] = useState(false);
  const [requestCounter, setRequestCounter] = useState(0);
  const [classStyle, setClassStyle] = useState(className || "img-fluid");
  const api = useAxiosPrivate();

  const successImage = (res: any) => {
    setSrc(res);
  };
  const errorCallback = (err: any) => {
    setSrc("");
  };
  const loadImage = async () => {
    if (path) {
      var qParam: string = path;
      const res = await api.get(
        `/api/session/downloadUrl?objectPath=${qParam}`
      );
      setSrc(res.data);
      setTimeout(() => {
        setIsLoading(false);
      });
    } else {
      setSrc(notFoundImg || "");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    loadImage();
  }, [path]);


  return (
    <div className="text-center">
      {isLoading ? (
        <div style={{ paddingTop: "40px" }}>
          {" "}
          <i className="fa fa-spinner" style={{ fontSize: "25px" }}></i>
        </div>
      ) : (
        <img
          src={src}
          alt={"not found"}
          onClick={() => {
            if (findImg) findImg(index);
          }}
          className={classStyle}
          style={styleObject || { maxHeight: "300px" }}
          onError={(e: any) => {
            // e.target.src = src;
            if (requestCounter < 1) {
              loadImage();
              setRequestCounter(requestCounter + 1);
            }
          }}
        />
      )}
    </div>
  );
};

export default ImageLoader;
