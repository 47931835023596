import React from "react";
// import lang from "../language.json";
import axios from "axios";

let languages: any = {};

export const setLanguage = (data: any) => {
  languages = data;
};

export const getMessageText = (code: string, defaultMsg: string) => {
  let result: string = defaultMsg;
  try {
    result = languages.filter(({ MN }: any) => MN === code)[0].MSG;
  } catch (e) {
    result = defaultMsg;
  }

  return result;
};
