// sriniupdated 07/26/2024
import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Paper,
} from "@mui/material";

const CDSelect = (props: any) => {
  const [value, setValue] = useState<any>([]);
  const [keyname, setKeyName] = useState<any>("");
  const [color, setColor] = useState("1px solid #aaa");
  const [keyVal, setKeyVal] = useState<any>(null);
  let { data = [] } = props;
  const {
    label,
    disablePortal = false,
    value: defaultValue,
    onChange: onChangeValue,
    onClear,
    disabled,
    onSelect,
    disableClearable,
    dropDown,
    isMultiple = false,
    isMultiLine = false,
    labelField,
    error: errors,
  } = props;

  const [selectAll, setSelectAll] = useState<boolean>(true);

  // NOTE: For multi select always send initial value as null so by defafult it check as all.'

  const setDefaultValue = () => {
    if (!isMultiple) {
      const keyVal = Object.keys(defaultValue)[0];
      const filterData = data?.filter((items: any) => {
        return items[keyVal] === defaultValue[keyVal];
      });
      if (filterData[0]) {
        setValue(filterData[0]);
      } else {
        setValue(defaultValue);
      }
    } else {
      setValue(defaultValue);
    }
  };

  useEffect(() => {
    setDefaultValue();
  }, [(!isMultiple && defaultValue[keyVal]) || (isMultiple && defaultValue)]);

  useEffect(() => {
    //Initial values
    try {
      if (keyVal) {
        data = data.map((items: any, index: number) => ({
          ...items,
          id: items[keyVal] + index + 1,
        }));
      }
      if (defaultValue !== null && defaultValue !== undefined) {
        getOrgName(defaultValue, data);
      }
      if ((defaultValue === null || defaultValue === undefined) && isMultiple) {
        setSelectAll(true);
        setValue(["All"]);
        onChangeValue(["All"]);
      }
    } catch (e: any) {
      console.log(e, "Something went wrong!");
    }
  }, [data]);

  const getOrgName = (val: any, allData: any) => {
    //Converting Object with Keys and Setting Values
    try {
      if (!isMultiple) {
        const keyVal = Object.keys(val)[0];
        setKeyVal(keyVal);
        setKeyName(
          keyVal === "orgId" || keyVal === "saasOrgId" ? "orgName" : keyVal
        );
        const filterData = allData?.filter((items: any) => {
          return items[keyVal] == val[keyVal];
        });
        if (filterData?.length !== 0) {
          setValue(filterData[0]);
          if (onSelect) {
            onSelect(filterData[0]);
          }
        } else {
          if (keyVal === "orgName") {
            setValue(
              val[keyVal] === "All" ? { orgName: "All" } : { orgName: "" }
            );
          } else {
            setValue(
              val[keyVal] === "All" ? { keyVal: "All" } : { keyVal: "" }
            );
          }
        }
      } else {
        setValue(val);
      }
    } catch (e: any) {
      console.log(e, "Something Error");
    }
  };

  useEffect(() => {
    // If validation errors change color
    if (errors) setColor("1px solid #f00");
    else setColor("1px solid #aaa");
  }, [errors]);

  const handleToggleSelectAll = () => {
    // Multiple select option display
    setSelectAll((prev) => {
      if (!prev) {
        setValue(["All"]);
        onChangeValue(["All"]);
      } else {
        setValue([]);
        onChangeValue([]);
      }
      return !prev;
    });
  };

  return (
    <>
      <FormControl fullWidth>
        <Autocomplete
          sx={{
            "& .MuiAutocomplete-inputRoot": {
              overflowY: "auto",
              height: isMultiple ? 40 : 40,
              maxHeight: isMultiple ? 100 : 40,
              border: isMultiple ? color : "auto",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: isMultiple ? "2px" : "auto",
            },
            "& .MuiAutocomplete-inputRoot:hover, .MuiAutocomplete-inputRoot:active":
              {
                border: isMultiple ? "1px solid #1088F2" : "auto",
              },
            "& .MuiOutlinedInput-notchedOutline": {
              border: isMultiple ? "none" : "auto",
              background: isMultiple ? "none" : "auto",
            },
            "& .css-1vr5s0e-MuiFormControl-root-MuiTextField-root .MuiInputLabel-root":
              {
                lineHeight: isMultiple ? 1.5 : "auto",
                background: isMultiple ? "#fff" : "auto",
                height: isMultiple ? 20 : "auto",
                padding: isMultiple ? "3px" : "auto",
              },
          }}
          multiple={isMultiple ? true : false}
          limitTags={2}
          disablePortal={disablePortal}
          freeSolo={dropDown ? false : true}
          value={value}
          disableClearable={disableClearable}
          disabled={disabled}
          onClose={(event: any, newValue) => {
            if (newValue !== "selectOption") {
              if (event?.target?.value === "") {
                if (onClear) onClear();
              }
            }
          }}
          onChange={(event, newValue) => {
            if (!newValue) {
              setValue([]);
              onChangeValue([]);
            } else {
              if (!isMultiple && keyname) {
                // timeout to avoid instant validation of the dialog's form.
                setTimeout(() => {
                  setValue(newValue);
                  onChangeValue(newValue);
                });
              } else {
                setSelectAll(false);
                const removeAll = newValue?.filter(
                  (item: any) => item !== "All"
                );
                setValue(removeAll);
                onChangeValue(removeAll);
              }
            }
          }}
          options={data}
          clearOnBlur={true}
          getOptionLabel={(option: any) => {
            if (typeof option === "string") {
              return option;
            }
            return (((labelField && option[labelField]) ? option[labelField] :  option[keyname]) || "");
          }}
          handleHomeEndKeys
          renderOption={(props, option: any) =>
            !isMultiLine ? (
              <li {...props} key={props?.id}>
                {(labelField && option[labelField]) ? option[labelField] :  keyname ? option[keyname] : option || ""}{" "}
              </li>
            ) : (
              <>
                <li {...props}>
                  <div className="d-flex row">
                    <div>
                      <span style={{ fontSize: "0.8rem", color: "green" }}>
                        {" "}
                        Name:{" "}
                      </span>
                      <span style={{ fontSize: "0.8rem", color: "blue" }}>
                        {option.Name}
                      </span>
                    </div>
                    <div>
                      <span style={{ fontSize: "0.8rem", color: "green" }}>
                        {" "}
                        Type:{" "}
                      </span>
                      <span style={{ fontSize: "0.8rem", color: "blue" }}>
                        {option.type === "DISCOUNT_COUPON"
                          ? "Discount Coupon"
                          : option.type === "SUBSCRIPTION"
                          ? "Subscription"
                          : option.type}
                      </span>
                    </div>
                    <div>
                      <span style={{ fontSize: "0.8rem", color: "green" }}>
                        {" "}
                        Code:{" "}
                      </span>
                      <span style={{ fontSize: "0.8rem", color: "blue" }}>
                        {option.couponCode}
                      </span>
                    </div>
                    <div>
                      <span style={{ fontSize: "0.8rem", color: "green" }}>
                        {" "}
                        Amount:{" "}
                      </span>
                      <span style={{ fontSize: "0.8rem", color: "blue" }}>
                        $ {option.amount}
                      </span>
                    </div>
                  </div>
                  <hr />
                </li>
              </>
            )
          }
          renderInput={(params) => (
            <TextField {...params} label={label} error={errors} />
          )}
          PaperComponent={(paperProps) => {
            const { children, ...restPaperProps } = paperProps;
            return (
              <Paper {...restPaperProps}>
                {isMultiple && data?.length ? (
                  <Box
                    onMouseDown={(e) => e.preventDefault()}
                    pl={1.5}
                    py={0.5}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault();
                        handleToggleSelectAll();
                      }}
                      label="Select all"
                      control={
                        <Checkbox
                          id="select-all-checkbox"
                          checked={selectAll}
                        />
                      }
                    />
                  </Box>
                ) : (
                  ""
                )}

                <Divider />
                {children}
              </Paper>
            );
          }}
        />
      </FormControl>
    </>
  );
};

export default CDSelect;
