import axios from "axios";
import { LocalStorageUtil } from "../utils/LocalStorageUtil";


export const getAxiosWithOutToken = ()=>axios.create({
  baseURL: LocalStorageUtil.getBaseURL(),
});


export const getAxiosInstance = ()=>{
  let token = "";
try {
  token = JSON.parse(LocalStorageUtil.getItem("token"));
} catch (e) {
  token = "";
}

const api = axios.create({
  baseURL: LocalStorageUtil.getItem("baseUrl"),
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
return api;
}
// export default api;
