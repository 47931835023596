import React from "react";
import { getMessageText } from "../../utils/message";
import * as LangConst from "../../utils/constant/LangConst";
import { useLocation } from "react-router-dom";
function Footer() {
  const location = useLocation();
  if (location.pathname !== "/globaldashboard")
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              Finpark © &nbsp;
              {getMessageText(
                LangConst.ALLRIGHTS_RESERVED,
                "All Rights Reserved."
              )}
            </div>
            <div className="col-sm-6 text-end tandc">
              <a
                href="../TermsAndConditions.pdf"
                target="_blank"
                className="text-muted link-underline "
              >
                {getMessageText(
                  LangConst.TERMS_CONDITIONS,
                  "Terms & Conditions"
                )}{" "}
              </a>
              {getMessageText(LangConst.AND, "and")}{" "}
              <a
                href="../Privacypolicy.pdf"
                target="_blank"
                className=" text-muted link-underline"
              >
                &nbsp;
                {getMessageText(
                  LangConst.PRIVACY_POLICY,
                  "Privacy Policy"
                )}{" "}
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  return <></>;
}

export default Footer;
