import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";

const LocalStorageUtil = () => {
  return <></>;
};
LocalStorageUtil.ldata = "";
LocalStorageUtil.ldataKey = "";
LocalStorageUtil.baseURL = "";
LocalStorageUtil.googleMapKey = "";

LocalStorageUtil.setBaseURL = (url: any) => {
  LocalStorageUtil.baseURL = url;
};
LocalStorageUtil.getBaseURL = () => {
  return LocalStorageUtil.baseURL;
};
LocalStorageUtil.setGKey = (key: any) => {
  LocalStorageUtil.googleMapKey = key;
};
LocalStorageUtil.getGKey = () => {
  return LocalStorageUtil.googleMapKey;
};

LocalStorageUtil.register = (key: any) => {
  LocalStorageUtil.ldataKey = key;
  try {
    if (localStorage.getItem(key)) {
      LocalStorageUtil.ldata = String(localStorage.getItem(key));
    }
  } catch (e: any) {
    LocalStorageUtil.ldata = "";
    localStorage.setItem(key, "");
  }
  localStorage.setItem("lastLoginKey", key);
  LocalStorageUtil.setItem("baseUrl", LocalStorageUtil.baseURL);
  LocalStorageUtil.setItem("googleMapKey", LocalStorageUtil.googleMapKey);
};
LocalStorageUtil.setItem = (key: any, value: any) => {
  let localData: any = {};
  const selectedUser: any =
    LocalStorageUtil.ldataKey ||
    localStorage.getItem("lastLoginKey") ||
    "finpark";
  try {
    localData = JSON.parse(String(localStorage.getItem(selectedUser)));
    if (!localData) {
      localData = {};
    }
  } catch (e: any) {
    localData = {};
  }

  localData[key] = value;

  localStorage.setItem(selectedUser, JSON.stringify(localData));
};

LocalStorageUtil.getItem = (key: any) => {
  let localData: any = {};
  const selectedUser: any =
    LocalStorageUtil.ldataKey ||
    localStorage.getItem("lastLoginKey") ||
    "finpark";
  try {
    localData = JSON.parse(String(localStorage.getItem(selectedUser)));
    if (!localData) {
      localData = {};
    }
  } catch (e: any) {
    localData = {};
  }
  return localData[key]||'""';
};

LocalStorageUtil.removeItem = (key: any) => {
  let localData: any = {};
  const selectedUser: any =
    LocalStorageUtil.ldataKey ||
    localStorage.getItem("lastLoginKey") ||
    "finpark";
  try {
    if (localStorage.getItem("finpark")) localStorage.removeItem("finpark");
    localData = JSON.parse(String(localStorage.getItem(selectedUser)));
    if (!localData) {
      localData = {};
    }
  } catch (e: any) {
    localData = {};
  }
  delete localData[key];
  localStorage.setItem(selectedUser, JSON.stringify(localData));
};

export { LocalStorageUtil };
