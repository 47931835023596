import { getAxiosInstance } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import LoadingCount from "../utils/constant/loadingCouter";
import { LocalStorageUtil } from "../utils/LocalStorageUtil";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const axiosPrivate = getAxiosInstance();
  let token: any = "";
  try {
    token = JSON.parse(String(LocalStorageUtil.getItem("token")));
  } catch (e: any) {
    token = "";
  }

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        LoadingCount.incrementCount();
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => {
        LoadingCount.decrementCount();
        return response;
      },
      async (error) => {
        const prevRequest = error?.config;
        LoadingCount.decrementCount();
        if (
          error?.response?.data?.errorCode === "TOKEN_EXPIRED" &&
          !prevRequest?.sent
        ) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivate(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [token, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
