import React from 'react'
import { LocalStorageUtil } from '../../utils/LocalStorageUtil';

const GoogleScriptLoader = () => {

    var mapsURL = `https://maps.googleapis.com/maps/api/js?key=${LocalStorageUtil.getGKey()}&libraries=geometry,places`;

    const googleMapScript = document.createElement('script');
    googleMapScript.src = mapsURL;
   
    window.document.head.appendChild(googleMapScript);

    return googleMapScript;
}

export default GoogleScriptLoader