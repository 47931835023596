import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingCount = () => {
  const [lCounter, setLCounter] = useState(0);
  useEffect(() => {
    LoadingCount.setter(setLCounter);
  }, []);

  if (lCounter > 0)
    return (
      <>
        <div style={{ position: "fixed", zIndex: "2000" }}>
          <div
            style={{
              display: "flex",
              width: window.innerWidth,
              height: window.innerHeight,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" size="60px" value={lCounter} />
            {/* <CircularProgress color="secondary" />
                <CircularProgress color="success" />
                <CircularProgress color="inherit" /> */}
          </div>
        </div>
      </>
    );
  return <></>;
};
export default LoadingCount;
LoadingCount.setterFun = (num: any) => {};
LoadingCount.setter = (fun: any) => {
  LoadingCount.setterFun = fun;
};
LoadingCount.counter = 0;

LoadingCount.getCount = () => {
  return LoadingCount.counter;
};

LoadingCount.setCount = (num: number) => {
  LoadingCount.counter = num;
};

LoadingCount.incrementCount = () => {
  LoadingCount.counter++;
  LoadingCount.setterFun(LoadingCount.counter);
};

LoadingCount.decrementCount = () => {
  LoadingCount.counter--;
  LoadingCount.setterFun(LoadingCount.counter);
};
