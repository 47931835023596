import { getAllOrgDetails } from "../../store/api";
import { store } from "../../store/store";
import { LocalStorageUtil } from "../../utils/LocalStorageUtil";
import { showToast } from "../../utils/tools";

export const getOrganizationList = (
  api: any,
  ignoreFilter: boolean = false,
  callback: any
) => {
  const dispatch = store.dispatch;
  // if (LocalStorageUtil.getItem("root") === "true") {
  //   try {
  //     const { data } = await api.get("/api/organization/getAllOrganization");
  //     const orgs = data.organizationApiList.content
  //       .filter(
  //         (org: any) =>
  //           ignoreFilter ||
  //           (org.isOrgApproved === "Approved" && org.orgStatus === "Active")
  //       )
  //       .map((item: any) => {
  //         return {
  //           orgId: item.orgId,
  //           orgName: item.orgName,
  //           saasOrgId: item.saasOrgId,
  //           orgStatus: item.orgStatus,
  //           usageType: item.usageType,
  //         };
  //       });
  //     callback(orgs);
  //   } catch (e: any) {
  //     if (e.response.status >= 400 && e.response.status < 500)
  //       showToast("ERROR", e.response.data.errorMessage);
  //     else if (e.response.status > 500) showToast("ERROR", e.message);
  //   }
  // } else {
  //   callback([
  //     {
  //       orgId: JSON.parse(String(LocalStorageUtil.getItem("saasOrgId"))),
  //       orgName: JSON.parse(String(LocalStorageUtil.getItem("orgName"))),
  //       saasOrgId: JSON.parse(String(LocalStorageUtil.getItem("saasOrgId"))),
  //     },
  //   ]);
  // }

  dispatch(
    getAllOrgDetails({
      callback: callback,
      api: api,
      ignoreFilter: ignoreFilter,
    })
  );
};
