import { createSlice } from "@reduxjs/toolkit";

const initstate: any = {
  selectedParkingLotDetails: {},
  selectedDriverDetails: {},
  selectedReservation: {},
  updateExtendReservation: {},
};
export const PreBookingSlice = createSlice({
  name: "PreBooking",
  initialState: initstate,
  reducers: {
    setSelectedParkingLotDetails: (state, action) => {
      state.selectedParkingLotDetails = { ...action.payload };
    },
    setSelectedDriverDetails: (state, action) => {
      state.selectedDriverDetails = { ...action.payload };
      state.selectedDriverAssociatedOrg = "";
    },
    setSelectedDriverSelectedTruck: (state, action) => {
      state.selectedDriverDetails = {
        ...state.selectedDriverDetails,
        detailsId: action.payload,
      };
    },
    setSelectedDriverSelectedCard: (state, action) => {
      state.selectedDriverDetails = {
        ...state.selectedDriverDetails,
        cardId: action.payload,
      };
    },
    setSelectedReservation: (state, action) => {
      state.selectedReservation = { ...action.payload };
    },
    setUpdateExtendCount: (state, action) => {
      state.updateExtendReservation = { ...action.payload };
    },
    setSelectedDriverAssociatedOrg: (state, action) => {
      state.selectedDriverAssociatedOrg = action.payload;
    },
    clearData: (state) => {
      state.selectedParkingLotDetails = {};
      state.selectedDriverDetails = {};
      state.selectedReservation = {};
      state.selectedDriverAssociatedOrg = "";
    },
  },
});

export const {
  setSelectedParkingLotDetails,
  setSelectedDriverDetails,
  setSelectedDriverSelectedTruck,
  setSelectedDriverSelectedCard,
  setSelectedReservation,
  setUpdateExtendCount,
  clearData,
  setSelectedDriverAssociatedOrg,
} = PreBookingSlice.actions;

export default PreBookingSlice.reducer;
