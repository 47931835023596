import { createSlice } from "@reduxjs/toolkit";

const initstate: any = {
  selectedParkingLotId: "none",
  selectedParkingDetails: {},
  selectedTabId: { tab: "nav-home-tab", name: "", id: "", phone:"", parkingId:"", parkingName:"" },
};
export const ParkingLotsSlice = createSlice({
  name: "ParkingLots",
  initialState: initstate,
  reducers: {
    setSelectedPId: (state, action) => {
      state.selectedParkingLotId = action.payload;
    },
    selectedTabId: (state, action) => {
      state.selectedTabId = action.payload;
    },
    setSelectedParkingDetails: (state, action) => {
      let images = [];
      try {
        images = action.payload.attachments.map((item: any) => {
          return {
            imagePath: item.imageFilePath,
            imagePriority: String(item.priority).toLowerCase(),
          };
        });
      } catch (e: any) {
        images = [];
      }
      state.selectedParkingDetails = { ...action.payload, images: images };
    },
  },
});

export const { setSelectedPId, selectedTabId, setSelectedParkingDetails } =
  ParkingLotsSlice.actions;

export default ParkingLotsSlice.reducer;
