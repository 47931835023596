import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Routes,
  useLocation,
} from "react-router-dom";
import React from "react";
import Navbar from "../components/Navigation/NewHeader";
import SideDrawer from "../components/Navigation/sideNavigation";
import Footer from "../components/Navigation/Footer";
import Dashborad from "../pages/DashboardPages/Dashborad";
//import MapDashBoard from "../pages/DashboardPages/mapdashboard";
//import InvoiceDashboard from "../pages/DashboardPages/InvoiceDashboard";
//import CompanyProfile from "../pages/AdminPages/CompanyProfile";
//import Organizations from "../pages/AdminPages/Organizations";
//import Amenities from "../pages/AdminPages/Amenities";
//import ParkingLot from "../pages/AdminPages/ParkingLot";
//import ParkingInfo from "../components/parkinglotComponent/ParkingInfo";
//import ErrorPage from "../pages/errorpage/Errorpage";
import CircularProgress from "@mui/material/CircularProgress";
// import Login from "../pages/Login/Login";
//import LoginPage from "../pages/Login/LoginPage";
import ErrorBoundary from "../error/ErrorBoundary";
//import Drivers from "../pages/Reports/Drivers";
//import Reservations from "../pages/Reports/Reservations";
//import ParkinglotPricing from "../pages/Reports/ParkinglotPricing";
//import RegistrationPage from "../pages/Login/RegistrationPage";
//import CompanyDashboard from "../pages/DashboardPages/CompanyDashboard";
//import RedirectionPage from "../pages/RedirectionPage";
//import ReservationParkDetails from "../pages/Reports/ReservationParkDetails";
import LoadingCount from "../utils/constant/loadingCouter";
//import SignUpPage from "../pages/Login/SignUpPage";
//import SOIDashboard from "../pages/DashboardPages/SOIDashboard";
//import ParkingLotAccessCode from "../pages/AdminPages/ParkingLotAccessCode";
//import DiscountCode from "../pages/AdminPages/DiscountCode";
//import BookReservations from "../pages/AdminPages/BookReservations";
//import DiscountCodeInvoice from "../pages/DashboardPages/DiscountCodeInvoce";
import { Suspense } from "react";
import SuccessDialog from "../components/BookReservationComponents/SuccessDialog";
const RedirectionPage = React.lazy(() => import("../pages/RedirectionPage"));
const CustomerLogin = React.lazy(() => import("../pages/Staticpages"));
const SignUpPage = React.lazy(() => import("../pages/Login/SignUpPage"));
const LoginPage = React.lazy(() => import("../pages/Login/LoginPage"));
const CompanyDashboard = React.lazy(
  () => import("../pages/DashboardPages/CompanyDashboard")
);
const NewMapDashBoard = React.lazy(
  () => import("../pages/DashboardPages/NewMapDashBoard")
);
const MapDashBoard = React.lazy(
  () => import("../pages/DashboardPages/mapdashboard")
);
const RegistrationPage = React.lazy(
  () => import("../pages/Login/RegistrationPage")
);
const InvoiceDashboard = React.lazy(
  () => import("../pages/DashboardPages/InvoiceDashboard")
);
const SOIDashboard = React.lazy(
  () => import("../pages/DashboardPages/SOIDashboard")
);
const DiscountCodeInvoice = React.lazy(
  () => import("../pages/DashboardPages/DiscountCodeInvoce")
);
const Organizations = React.lazy(
  () => import("../pages/AdminPages/Organizations")
);
const CompanyProfile = React.lazy(
  () => import("../pages/AdminPages/CompanyProfile")
);
const Amenities = React.lazy(() => import("../pages/AdminPages/Amenities"));
const ParkingLot = React.lazy(() => import("../pages/AdminPages/ParkingLot"));
const CarrierOrganization = React.lazy(
  () => import("../pages/CarrierNetwork/CarrierOrganization")
);
const AddCarrier = React.lazy(
  () => import("../pages/CarrierNetwork/AddCarrier")
);
const CarrierAssets = React.lazy(
  () => import("../pages/CarrierNetwork/CarrierAssets")
);
const Payments = React.lazy(() => import("../pages/CarrierNetwork/Payments"));
const ParkingInfo = React.lazy(
  () => import("../components/parkinglotComponent/ParkingInfo")
);
const AddCarrierAsset = React.lazy(
  () => import("../pages/CarrierNetwork/AddCarrierAsset")
);
const BookReservations = React.lazy(
  () => import("../pages/AdminPages/BookReservations")
);
const Assets = React.lazy(() => import("../pages/AdminPages/Assets"));
const AssetType = React.lazy(() => import("../pages/AdminPages/AssetType"));
const Equipment = React.lazy(() => import("../pages/AdminPages/Equipment"));
const Drivers = React.lazy(() => import("../pages/Reports/Drivers"));

const CreateDriver = React.lazy(() => import("../pages/AdminPages/Driver"));
const Reservations = React.lazy(() => import("../pages/Reports/Reservations"));
const Revenue = React.lazy(() => import("../pages/Reports/Revenue"));
const SubscriptionReport = React.lazy(
  () => import("../pages/Reports/SubscriptionReport")
);
const AssetReport = React.lazy(() => import("../pages/Reports/AssetReport"));
const ParkingLotReport = React.lazy(
  () => import("../pages/Reports/ParkingLotReport")
);
const ApiHub = React.lazy(() => import("../pages/API/ApiHub"));
const ReservationParkDetails = React.lazy(
  () => import("../pages/Reports/ReservationParkDetails")
);
const ParkinglotPricing = React.lazy(
  () => import("../pages/Reports/ParkinglotPricing")
);
const ParkingLotAccessCode = React.lazy(
  () => import("../pages/AdminPages/ParkingLotAccessCode")
);
const DiscountCode = React.lazy(
  () => import("../pages/AdminPages/DiscountCode")
);

const GlobalDashboard = React.lazy(
  () => import("../pages/Login/GlobalDashboard")
);

const FinparkReserve = React.lazy(() => import("../pages/FinparkReserve"));

const ErrorPage = React.lazy(() => import("../pages/errorpage/Errorpage"));

const Layout = () => {
  const location = useLocation();
  return (
    <>
      <Suspense
        fallback={
          <div className="">
            <CircularProgress color="primary" thickness={1} />
          </div>
        }
      >
        <Navbar />
        <SideDrawer />
        <SuccessDialog />
        {location.pathname !== "/globaldashboard" ? (
          <div style={{ marginTop: "64px" }}></div>
        ) : (
          ""
        )}
        <LoadingCount />
        <Outlet />
        <Footer />
      </Suspense>
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RedirectionPage />,
  },
  {
    path: "/customerLogin/:customer",
    element: <CustomerLogin />,
  },

  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/SignUpPage",
        element: (
          <ErrorBoundary>
            <SignUpPage />
          </ErrorBoundary>
        ),
      },
      {
        path: "/LoginPage",
        element: (
          <ErrorBoundary>
            <LoginPage />
          </ErrorBoundary>
        ),
      },
      {
        path: "/dashboard",
        element: (
          <ErrorBoundary>
            <CompanyDashboard />
          </ErrorBoundary>
        ),
      },
      // {
      //   path: "/newMap",
      //   element: (
      //     <ErrorBoundary>
      //       <NewMapDashBoard />
      //     </ErrorBoundary>
      //   ),
      // },
      {
        path: "/finparkRegistration",
        element: (
          <ErrorBoundary>
            <RegistrationPage />
          </ErrorBoundary>
        ),
      },
      {
        path: "/invoiceDashboard",
        element: (
          <ErrorBoundary>
            <InvoiceDashboard />
          </ErrorBoundary>
        ),
      },
      {
        path: "/soiDashboard",
        element: (
          <ErrorBoundary>
            <SOIDashboard />
          </ErrorBoundary>
        ),
      },
      {
        path: "/discountcodeinvoice",

        element: (
          <ErrorBoundary>
            <DiscountCodeInvoice />
          </ErrorBoundary>
        ),
      },
      {
        path: "/organizationDetails",
        element: (
          <ErrorBoundary>
            <Organizations />
          </ErrorBoundary>
        ),
      },
      {
        path: "/companyProfile",
        element: (
          <ErrorBoundary>
            <CompanyProfile />
          </ErrorBoundary>
        ),
      },
      {
        path: "/amenities",
        element: (
          <ErrorBoundary>
            <Amenities />
          </ErrorBoundary>
        ),
      },
      {
        path: "/parkingLot",
        element: (
          <ErrorBoundary>
            <ParkingLot />
          </ErrorBoundary>
        ),
      },
      {
        path: "/createDriver",
        element: (
          <ErrorBoundary>
            <CreateDriver />
          </ErrorBoundary>
        ),
      },
      {
        path: "/parkingInfo",
        element: (
          <ErrorBoundary>
            <ParkingInfo />
          </ErrorBoundary>
        ),
      },
      {
        path: "/organizationDetails/editOrganization",
        element: (
          <ErrorBoundary>
            <CompanyProfile />
          </ErrorBoundary>
        ),
      },
      {
        path: "/bookReservations",
        element: (
          <ErrorBoundary>
            <BookReservations />
          </ErrorBoundary>
        ),
      },
      {
        path: "/assets",
        element: (
          <ErrorBoundary>
            <Assets />
          </ErrorBoundary>
        ),
      },
      {
        path: "/assetType",
        element: (
          <ErrorBoundary>
            <AssetType />
          </ErrorBoundary>
        ),
      },
      {
        path: "/equipmentType",
        element: (
          <ErrorBoundary>
            <Equipment />
          </ErrorBoundary>
        ),
      },
      {
        path: "/carrierOrganization",
        element: (
          <ErrorBoundary>
            <CarrierOrganization />
          </ErrorBoundary>
        ),
      },
      {
        path: "/addCarrier",
        element: (
          <ErrorBoundary>
            <AddCarrier />
          </ErrorBoundary>
        ),
      },
      {
        path: "/editCarrierDetails",
        element: (
          <ErrorBoundary>
            <AddCarrier />
          </ErrorBoundary>
        ),
      },
      {
        path: "/carrierAssets",
        element: (
          <ErrorBoundary>
            <CarrierAssets />
          </ErrorBoundary>
        ),
      },
      {
        path: "/sinvoice",
        element: (
          <ErrorBoundary>
            <Payments />
          </ErrorBoundary>
        ),
      },
      {
        path: "/addCarrierAsset",
        element: (
          <ErrorBoundary>
            <AddCarrierAsset />
          </ErrorBoundary>
        ),
      },
      {
        path: "/drivers",
        element: (
          <ErrorBoundary>
            <Drivers />
          </ErrorBoundary>
        ),
      },
      {
        path: "/reservations",
        element: (
          <ErrorBoundary>
            <Reservations />
          </ErrorBoundary>
        ),
      },
      {
        path: "/revenue",
        element: (
          <ErrorBoundary>
            <Revenue />
          </ErrorBoundary>
        ),
      },
      {
        path: "/subscription",
        element: (
          <ErrorBoundary>
            <SubscriptionReport />
          </ErrorBoundary>
        ),
      },
      {
        path: "/assetReport",
        element: (
          <ErrorBoundary>
            <AssetReport />
          </ErrorBoundary>
        ),
      },
      {
        path: "/parkingLotReport",
        element: (
          <ErrorBoundary>
            <ParkingLotReport />
          </ErrorBoundary>
        ),
      },
      {
        path: "/reservationparkdetails",
        element: (
          <ErrorBoundary>
            <ReservationParkDetails />
          </ErrorBoundary>
        ),
      },
      {
        path: "/reservationparkdetails/:reservationid",
        element: (
          <ErrorBoundary>
            <ReservationParkDetails />
          </ErrorBoundary>
        ),
      },
      {
        path: "/parkinglotpricing",
        element: (
          <ErrorBoundary>
            <ParkinglotPricing />
          </ErrorBoundary>
        ),
      },
      {
        path: "/parkinglotAccessCode",
        element: (
          <ErrorBoundary>
            <ParkingLotAccessCode />
          </ErrorBoundary>
        ),
      },
      {
        path: "/discountCode",
        element: (
          <ErrorBoundary>
            <DiscountCode />
          </ErrorBoundary>
        ),
      },
      {
        path: "/globaldashboard",
        element: (
          <ErrorBoundary>
            <GlobalDashboard />
          </ErrorBoundary>
        ),
      },
      {
        path: "/reserve",
        element: (
          <ErrorBoundary>
            <FinparkReserve />
          </ErrorBoundary>
        ),
      },
      {
        path: "/apihub",
        element: (
          <ErrorBoundary>
            <ApiHub />
          </ErrorBoundary>
        ),
      },
      {
        path: "/mapDashboard",
        element: (
          <ErrorBoundary>
            <NewMapDashBoard />
          </ErrorBoundary>
        ),
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
  // {
  //   path: "/mapDashboard",
  //   element: <MapDashBoard />,
  // },
  // {
  //   path: "/invoiceDashboard",
  //   element: <InvoiceDashboard />,
  // },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },
  // {
  //   path: "/login",
  //   element: <Login />,
  // },
]);
