import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Select, MenuItem, InputLabel, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getMessageText } from "../../utils/message";
import * as LangConst from "../../utils/constant/LangConst";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { showToast } from "../../utils/tools";
import PaperComponent from "../DraggableComponent/PaperComponent";
import { LocalStorageUtil } from "../../utils/LocalStorageUtil";
interface CustomNodeYesProps {
  show: boolean;
  onCancelDialogue: Function;
}

const ChangePassword = ({ show, onCancelDialogue }: CustomNodeYesProps) => {
  const [open, setOpen] = useState(false);
  const localUserId: any = LocalStorageUtil.getItem("userId");
  let userId: any = "";
  try {
    userId = JSON.parse(localUserId);
  } catch (e) {
    userId = "";
  }
  const api = useAxiosPrivate();
  const handleClose = () => {
    setOpen(false);
    onCancelDialogue();
  };
  useEffect(() => {
    setOpen(show);
  }, [show]);

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmnewPassword: "",
  };
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(
      getMessageText(LangConst.ENTER_OLD_PASSWORD, "Enter Old Password")
    ),
    newPassword: Yup.string().required(getMessageText(LangConst.ENTER_NEW_PASSWORD, "Enter New Password")),
    confirmnewPassword: Yup.string()
      .required(getMessageText(LangConst.ENTER_CONFIRMED_NEW_PASSWORD, "Enter Confirmed New Password"))
      .oneOf([Yup.ref("newPassword")], getMessageText(LangConst.YOUR_PASSWORD_DO_NOT_MATCH, "Your passwords do not match.")),
  });
  const onSubmit = async (values: any) => {
    const request = {
      newPassword: values.newPassword,
      oldPassword: values.oldPassword,
      userId: userId,
    };
    try {
      await api.put(`/api/user/updatePassword/${userId}`, request);
      onCancelDialogue();
      showToast("SUCCESS", getMessageText(LangConst.UPDATED_SUCCESSFULLY, "Updated Successfully"));
    } catch (error: any) {
      if (error?.response?.data?.errorMessage)
        showToast("ERROR", error.response.data.errorMessage);
      else showToast("ERROR", getMessageText(LangConst.ERROR_RESETTING_PASSWORD, "Error resetting the password"));
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          sx: {
            width: "35%",
            maxHeight: "80%",
            borderRadius: "0.8rem",
          },
        }}
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {getMessageText(LangConst.CHANGE_PASSWORD, "Change Password")}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form noValidate>
                <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                  <div className="mb-3 mt-2 floating-label">
                    <Field
                      as={TextField}
                      name="oldPassword"
                      type="password"
                      label={getMessageText(
                        LangConst.OLD_PASSWORD,
                        "Old Password"
                      )}
                      fullWidth
                      // size="small"
                      // InputProps={{
                      //   style: {
                      //     borderRadius: "0.8rem",
                      //     height: "46px",
                      //     top: "-5px",
                      //   },
                      // }}
                      error={
                        formik.errors.oldPassword && formik.touched.oldPassword
                      }
                      helperText={<ErrorMessage name="oldPassword" />}
                    />
                  </div>
                  <div className="mb-3 mt-2 floating-label">
                    <Field
                      as={TextField}
                      name="newPassword"
                      type="password"
                      label={getMessageText(
                        LangConst.NEW_PASSWORD,
                        "New Password"
                      )}
                      fullWidth
                      // size="small"
                      // InputProps={{
                      //   style: {
                      //     borderRadius: "0.8rem",
                      //     height: "46px",
                      //     top: "-5px",
                      //   },
                      // }}
                      error={
                        formik.errors.newPassword && formik.touched.newPassword
                      }
                      helperText={<ErrorMessage name="newPassword" />}
                    />
                  </div>
                  <div className="mb-3 mt-2 floating-label">
                    <Field
                      as={TextField}
                      name="confirmnewPassword"
                      type="password"
                      label={getMessageText(
                        LangConst.CONFIRM_NEW_PASSWORD,
                        "Confirm NewPassword"
                      )}
                      fullWidth
                      // size="small"
                      // InputProps={{
                      //   style: {
                      //     borderRadius: "0.8rem",
                      //     height: "46px",
                      //     top: "-5px",
                      //   },
                      // }}
                      error={
                        formik.errors.confirmnewPassword &&
                        formik.touched.confirmnewPassword
                      }
                      helperText={<ErrorMessage name="confirmnewPassword" />}
                    />
                  </div>
                </div>

                <div className="modal-footer px-0">
                  <button
                    type="button"
                    className="btn min btn-outline-dark mx-lg-3 mx-md-3 font-size-12 text-uppercase"
                    data-bs-dismiss="modal"
                    onClick={handleClose}
                  >
                    {getMessageText(LangConst.CLOSE, "Close")}
                  </button>
                  <button
                    type="submit"
                    className="btn min btn-primary font-size-12 text-uppercase"
                  >
                    {getMessageText(LangConst.SAVE, "Save")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ChangePassword;
