import * as actions from "../store/actions/actions";

const initialState = {
  allOrganizations: [],
  companyNames: [],
  processing: false,
  error: null,
  isReload: false,
};

function GetCompanyNames(state = initialState, action: any) {
  switch (action.type) {
    case actions.LOAD_COMPANYNAMES_REQUEST:
      return { ...state, processing: true };
    case actions.LOAD_COMPANYNAMES_SUCCESS:
      return { ...state, processing: false, companyNames: action?.payload };
    case actions.LOAD_ORGANIZATIONS_SUCCESS:
      return { ...state,isReload:false, processing: false, allOrganizations: action?.payload };
    case actions.LOAD_COMPANYNAMES_FAILURE:
      return { ...state, processing: false, error: action?.payload?.message };
    case actions.ISRELOAD:
      return { ...state, isReload: action?.payload };
    default:
      return state;
  }
}

// dispatch(
//   StudentRegistrationDataPost({
//     apiPayloadRequest: values,
//     callback: onSuccesssubmitRegForm,
//   })
// );

//dispatch(GetRegistrationAmount({ callback: onGetRegamount }));

export default GetCompanyNames;
