import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import DashboardSlice from "../reducers/dashboardSlice";
import OrganizationSlice from "../reducers/organizationSlice";
import ParkingLotsSlice from "../reducers/parkinglotsSlice";
import PreBookingSlice from "../reducers/preBookingSlice";
import finparkReserveSlice from "../reducers/finparkReserveSlice";
import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import GetCompanyNames from "../reducers/CommonReducer";

const rootReducer = combineReducers({
  mapDashboard: DashboardSlice,
  organization: OrganizationSlice,
  parkingLots: ParkingLotsSlice,
  preBooking: PreBookingSlice,
  finparkReserve: finparkReserveSlice,
  getCompanyNames: GetCompanyNames,
});

// export const store = configureStore({
//   reducer: {
//     mapDashboard: DashboardSlice,
//     organization: OrganizationSlice,
//     parkingLots:ParkingLotsSlice,
//     preBooking:PreBookingSlice,
//     finparkReserve:finparkReserveSlice
//   },
// });

export const store = createStore(rootReducer, applyMiddleware(thunk));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
