import React from 'react';
import { Link } from "react-router-dom";
import {useEffect, useState} from 'react';
import { useSidebarContext } from "../../Contexts/SidebarContext";

const linkItems=[
    {
        linkName:"Dashboard",
        path:"/"
    },
    {
        linkName:"MapDashBoard",
        path:"/mapdashboard"
    },
    {
        linkName:"Organizations",
        path:"/organizations"
    },

    ]
const BreadCrumps = () => {
   
    const [links,setLinks] = useState([])

    const {breadCrumpsData} = useSidebarContext();
    useEffect(()=>{
        if(breadCrumpsData?.length > 0){
            setLinks(breadCrumpsData)
        }else{
            setLinks([]);
        }

    },[breadCrumpsData])
    
    return (
      <ol className="breadcrumb m-0">
        {
            links.map((item:any,index:number)=>{
                return (
                <li key={item.linkName} className={`breadcrumb-item ${index == (links.length -1) ? 'active' : ""}`}>
                    <Link to={item.path}>{item.linkName}</Link>
                </li>)
            })
        }
      </ol>
    );
  };

export default React.memo(BreadCrumps) ;


