import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Select, MenuItem, InputLabel, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { showToast } from "../../utils/tools";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getMessageText } from "../../utils/message";
import * as LangConst from "../../utils/constant/LangConst";
import PaperComponent from "../DraggableComponent/PaperComponent";
import { LocalStorageUtil } from "../../utils/LocalStorageUtil";
import { useSidebarContext } from "../../Contexts/SidebarContext";
interface CustomNodeYesProps {
  show: boolean;
  onCancelDialogue: Function;
}

const ProfileDialog = ({ show, onCancelDialogue }: CustomNodeYesProps) => {
  const [open, setOpen] = useState(false);
  const localUserId: any = LocalStorageUtil.getItem("userId");
  const [initData, setInitData] = useState<any>({});
  const [oldData, setOldData] = useState<any>({});
  const api = useAxiosPrivate();
  const { setUserFirstName,
    setUserLastName,
    changeUserName} = useSidebarContext();
  let userId: any = "";
  try {
    userId = JSON.parse(localUserId);
  } catch (e) {
    userId = "";
  }
  const handleClose = () => {
    setOpen(false);
    onCancelDialogue();
  };
  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
  };

  useEffect(() => {
    if (userId) {
      getUserDetails(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (show) {
      setOpen(show);
      getUserDetails(userId);
    }
  }, [show]);

  const getUserDetails = async (selectedContact: any) => {
    try {
      const { data } = await api.get(`/api/user/${selectedContact}`);
      const { loginId, firstName, lastName } = data;
      setOldData(data);
      setUserFirstName(firstName)
      setUserLastName(lastName)
      try{
      changeUserName(JSON.parse(LocalStorageUtil.getItem("orgName")));
      }catch(e){}
      setInitData({
        email: loginId,
        firstName: firstName,
        lastName: lastName,
      });
    } catch (error: any) {
      showToast("ERROR", error.response.data.statusCode);
    }
  };
  const saveUserDetails = async (values: any) => {
    try {
      const data = { ...oldData, ...values };
      await api.put(`/api/user/update/${userId}`, data);
      onCancelDialogue();
      showToast("SUCCESS", getMessageText(LangConst.UPDATED_SUCCESSFULLY, "Updated Successfully"));
      handleClose();
      getUserDetails(userId);
    } catch (error: any) {
      showToast("ERROR", error.response.data.errorMessage);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required(getMessageText(LangConst.ENTER_EMAIL, "Enter Email")),
    firstName: Yup.string().required(getMessageText(LangConst.ENTER_FIRST_NAME, "Enter First Name")),
    lastName: Yup.string().required(getMessageText(LangConst.ENTER_LAST_NAME, "Enter Last Name")),
  });
  const onSubmit = (values: any) => {
    saveUserDetails(values);
  };

  if (Object.keys(initData).length > 0)
    return (
      <div>
        <Dialog
          open={open}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          PaperProps={{
            sx: {
              width: "32%",
              maxHeight: "75%",
              borderRadius: "0.8rem",
            },
          }}
        >
          <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
            {getMessageText(LangConst.MY_PROFILE, "My Profile")}
          </DialogTitle>
          <DialogContent>
            <Formik
              initialValues={initData}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <Form noValidate>
                  <div style={{ marginTop: "30px", marginBottom: "30px" }}>
                    <div className="mb-3 mt-2 floating-label">
                      <Field
                        as={TextField}
                        name="email"
                        label={getMessageText(LangConst.EMAIL, "Email")}
                        fullWidth                       
                        disabled
                        //  size="small"
                        // InputProps={{
                        //   style: {
                        //     borderRadius: "0.5rem",
                        //     height: "46px",
                        //     top: "-5px",
                        //     backgroundColor: "#e9ecef",
                        //   },
                        // }}
                        error={formik.errors.email && formik.touched.email}
                        helperText={<ErrorMessage name="email" />}
                      />
                    </div>
                    <div className="mb-3 mt-2 floating-label">
                      <Field
                        as={TextField}
                        name="firstName"
                        label={getMessageText(
                          LangConst.FIRST_NAME,
                          "First Name"
                        )}
                        fullWidth
                        // size="small"
                        // InputProps={{
                        //   style: {
                        //     borderRadius: "0.5rem",
                        //     height: "46px",
                        //     top: "-5px",
                        //   },
                        // }}
                        error={
                          formik.errors.firstName && formik.touched.firstName
                        }
                        helperText={<ErrorMessage name="firstName" />}
                      />
                    </div>
                    <div className="mb-3 mt-2 floating-label">
                      <Field
                        as={TextField}
                        name="lastName"
                        label={getMessageText(LangConst.LAST_NAME, "Last Name")}
                        fullWidth
                        // size="small"
                        // InputProps={{
                        //   style: {
                        //     borderRadius: "0.5rem",
                        //     height: "46px",
                        //     top: "-5px",
                        //   },
                        // }}
                        error={
                          formik.errors.lastName && formik.touched.lastName
                        }
                        helperText={<ErrorMessage name="lastName" />}
                      />
                    </div>
                  </div>

                  <div className="modal-footer px-0">
                    <button
                      type="button"
                      className="btn min btn-outline-dark mx-lg-3 mx-md-3 font-size-12 text-uppercase"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                    >
                      {getMessageText(LangConst.CLOSE, "Close")}
                    </button>
                    <button
                      type="submit"
                      className="btn min btn-primary font-size-12 text-uppercase"
                    >
                      {getMessageText(LangConst.SAVE, "Save")}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </div>
    );
  return <></>;
};
export default ProfileDialog;
