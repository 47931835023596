import { Checkbox, FormControlLabel } from "@mui/material";
import * as LangConst from "../../utils/constant/LangConst";
import React, { useEffect } from "react";
import { LocalStorageUtil } from "../../utils/LocalStorageUtil";
import { getMessageText } from "../../utils/message";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { showToast } from "../../utils/tools";
import { useDispatch, useSelector } from "react-redux";
import { setisOrgNetworkConfigEnabled } from "../../reducers/organizationSlice";

const CarrierNetworkConfig = () => {
  const dispatch = useDispatch();
  const [allowCarrierNetwork, setAllowCarrierNetwork] = React.useState(false);
  const selectedOrg = useSelector(({ organization }: any) => {
    return organization.selectedInternalOrg;
  });
  const isOrgNetworkConfigEnabled = useSelector(({ organization }: any) => {
    return organization.isOrgNetworkConfigEnabled;
  });
  let isManager = false;
  const api = useAxiosPrivate();
  const role: any = JSON.parse(LocalStorageUtil.getItem("role") || "");
  if (role != "" && role === "Manager") isManager = true;

  useEffect(() => {
    setAllowCarrierNetwork(isOrgNetworkConfigEnabled);
  }, [isOrgNetworkConfigEnabled]);

  const handleTermsChange = (event: any) => {
    setAllowCarrierNetwork(event.target.checked);
  };

  const setOrgNetworkConfiguration = async () => {
    try {
      const { data } = await api.post(
        `/api/organization/orgNetworkConfiguration`,
        {
            "saasOrgId": selectedOrg,
            "isOrgNetworkConfigEnabled":allowCarrierNetwork
        }
      );
      showToast("SUCCESS", getMessageText(LangConst.UPDATED_SUCCESSFULLY, "Updated Successfully"));
      dispatch(setisOrgNetworkConfigEnabled(allowCarrierNetwork));
    } catch (e: any) {
      if (e.response.data.errorMessage)
        showToast("ERROR", e.response.data.errorMessage);
      else showToast("ERROR", e.message);
    }
  };

  const onSave = () => {
    setOrgNetworkConfiguration();
  };

  return (
    <div className="card mb-2">
      <div className="card-body in-dash">
        <div>Carrier Network</div>
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <FormControlLabel
              control={
                <Checkbox
                  checked={allowCarrierNetwork}
                  onChange={handleTermsChange}
                />
              }
              value={allowCarrierNetwork}
              onChange={(e: any) => {
                setAllowCarrierNetwork(e.target.checked);
              }}
              label="Enable Carrier Network Screen"
            />
             <div className="mx-2"><p style={{ color: "#001c81", fontSize: "10px" }}>Note: Payment and invoicing will be done on the last day of the month</p></div>
          </div>
        </div>

        <div className="maintabs-footer">
         
          <button
            type="button"
            onClick={onSave}
            className="btn min btn-primary font-size-12 text-uppercase"
            disabled={isManager ? true : false}
            // style={{display:"none"}}
          >
            {getMessageText(LangConst.SAVE, "Save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CarrierNetworkConfig;
