import CircularProgress from "@mui/material/CircularProgress";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import cookie from "react-cookies";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export const errorHelper = (formik, values) => ({
  error: formik.errors[values] && formik.touched[values] ? true : false,
  helperText:
    formik.errors[values] && formik.touched[values]
      ? formik.errors[values]
      : null,
});

export const Loader = () => (
  <div className="root_loader">
    <CircularProgress />
  </div>
);

export const showToast = (type, msg) => {
  switch (type) {
    case "SUCCESS":
      toast.success(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      break;
    case "ERROR":
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      break;
    case "WARNING":
      toast.warn(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      break;
    default:
      return false;
  }
};

export const getTokenCookie = () => cookie.load("x-access-token");
export const removeTokenCookie = () =>
  cookie.remove("x-access-token", { path: "/" });
export const getAuthHeader = () => {
  return { headers: { Authorization: `Bearer ${getTokenCookie()}` } };
};

export const AdminTitle = ({ title }) => (
  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
    <h1 className="h2">{title}</h1>
  </div>
);

export const LogoutPopup = ({ open, onClickOk }) => {
  const [openPop, setOpenPop] = useState(true);

  const handleClose = () => {
    setOpenPop(false);
    onClickOk();
  };
  useEffect(() => {
    setOpenPop(open);
  }, [open]);
  return (
    <>
      <Dialog
        open={openPop}
        PaperProps={{
          sx: {
            width: "35%",
            maxHeight: "80%",
            borderRadius: "0.8rem",
          },
        }}
      >
        <DialogTitle>Logged Out</DialogTitle>
        <DialogContent>
          Your Session has been Expired
          <button
            type="button"
            className="btn min btn-outline-dark mx-lg-3 mx-md-3 font-size-12 text-uppercase"
            data-bs-dismiss="modal"
            onClick={handleClose}
          >
            OK
          </button>
        </DialogContent>
      </Dialog>
    </>
  );
};
