import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { SidebarContextPrv } from "./Contexts/SidebarContext";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
// import { Typography } from "@material-ui/core";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import { router } from "./routing/routingmanger";
import axios from "axios";
import { useSidebarContext } from "./Contexts/SidebarContext";
import { setLanguage } from "./utils/message";
import { LocalStorageUtil } from "./utils/LocalStorageUtil";
import GoogleScriptLoader from "./components/maps/GoogleScriptLoader";

const theme = createTheme({
  typography: {
    fontSize: 12,

    fontFamily: [
      "Poppins",
      // "Roboto",
      // "Helvetica Neue",
      // "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    // Name of the component
    MuiPaper: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          boxShadow: "0px 0px 0px 1px rgb(140 140 140/.2)",
        },
      },
    },
    MuiTextField: {
      // defaultProps: {
      //   // size: 'small' as 'small' | 'medium',
      //   height: "50px",
      // },
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: "40px",
            display: "flex",
            alignItems: "center",
          },

          "& .MuiInputBase-input": {
            padding: 0,
            paddingLeft: "14px", // Adjust padding as needed
            height: "100%",
            boxSizing: "border-box",
          },
          "& .MuiFormLabel-root": {
            height: "40px",
            lineHeight: "12px",
            // marginTop:"-5px"
          },
          "& .MuiInputLabel-root": {
            height: "15px",
            lineHeight: "12px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-inputRoot": {
            height: "40px",
            display: "flex",
            alignItems: "center",
            overflowY: "auto",
            background: "#fff",
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        // root: {
        //   "& .MuiInputBase-root": {
        //     height: "40px",
        //     display: "flex",
        //     alignItems: "center",
        //   },
        //   "& .MuiInputBase-input": {
        //     padding: 0,
        //     paddingLeft: "14px",
        //     height: "100%",
        //     boxSizing: "border-box",
        //   },
        //   "& .MuiFormLabel-root": {
        //     height: "40px",
        //     lineHeight: "12px",
        //   },
        // },
      },
    },
  },
});

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    clearBrowserCache();
    loadServerandKeys();
    loadLanguages();
  }, []);

  const loadServerandKeys = async () => {
    let mili = new Date().getTime();
    const res = await axios.get(`../server.json?rand=${mili}`);
    try {
      LocalStorageUtil.setBaseURL(res.data.servers.server.url);
      LocalStorageUtil.setGKey(res.data.servers.server.gMapKey);
    } catch (e) {
      LocalStorageUtil.setBaseURL("http://54.226.131.201:8080");
      LocalStorageUtil.setGKey("AIzaSyC7SVyTtsbfDk7h7tjArGgNDnunO-ErGCw");
    }
    setLoading(false);
    GoogleScriptLoader();
  };
  const loadLanguages = async () => {
    let mili = new Date().getTime();
    const res = await axios.get(`../language.json?rand=${mili}`);
    setLanguage(res.data);
  };

  let clearBrowserCache = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
      // window.location.reload();
    }
  };

  if (loading) return <>Loading...</>;

  return (
    <>
      <link href="./assets/css/finpark.css" rel="stylesheet" type="text/css" />
      <div className="App">
        <ThemeProvider theme={theme}>
          <SidebarContextPrv>
            <RouterProvider router={router} />
          </SidebarContextPrv>
        </ThemeProvider>
      </div>
    </>
  );
}

export default App;
