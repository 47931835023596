import { createSlice } from "@reduxjs/toolkit";

const initstate: any = {
  selectedOrg: "none", //localStorage.getItem("saasOrgId") || 1,
  selectedInternalOrg: "none",
  selectedContact: "",
  selectedUserType: "",
  selectedCountry: "",
  isOrgApproved: "",
  isOrgNetworkConfigEnabled: "",
  userKey:"",
  userName: "",
};
export const OrganizationSlice = createSlice({
  name: "organization",
  initialState: initstate,
  reducers: {
    setSelectedOrg: (state, action) => {
      state.selectedOrg = action.payload;
      state.selectedInternalOrg = action.payload;
    },
    setUserKey: (state, action) => {
      state.userKey = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setSelectedInternalOrg: (state, action) => {
      state.selectedInternalOrg = action.payload;
    },
    setSelectedContact: (state, action) => {
      state.selectedContact = action.payload;
    },
    setSelectedUserType: (state, action) => {
      state.selectedUserType = action.payload;
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    setisOrgApproved: (state, action) => {
      state.isOrgApproved = action.payload;
    },
    setisOrgNetworkConfigEnabled: (state, action) => {
      state.isOrgNetworkConfigEnabled = action.payload;
    },
  },
});

export const {
  setSelectedInternalOrg,
  setSelectedOrg,
  setUserKey,
  setSelectedContact,
  setSelectedUserType,
  setSelectedCountry,
  setisOrgApproved,
  setisOrgNetworkConfigEnabled,
  setUserName,
} = OrganizationSlice.actions;

export default OrganizationSlice.reducer;
